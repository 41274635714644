import React from "react";
import { Helmet } from "react-helmet";

export default (props) => (
  <Helmet>
    <title>{props.title}</title>
    <link rel="canonical" href={props.getCanonicalLink(props.url ? props.url : "/")} />
    <link type="application/rss+xml" rel="alternate" href="https://feeds.simplecast.com/9QAt3nzf" />
    <meta name="description" content={props.description} />
    <meta property="og:url" content={props.getCanonicalLink(props.url ? props.url : "/")} />
    <meta property="og:type" content={props.type ? props.type : "website"} />
    <meta property="og:title" content={props.title} />
    <meta property="og:description" content={props.description} />
    <meta
      property="og:image"
      content={
        props.image
          ? props.image
          : "https://res.cloudinary.com/green-ocean/image/upload/q_auto/f_auto/v1523817901/web/landing-intro-background-wide.jpg"
      }
    />
  </Helmet>
);

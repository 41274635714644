import React from "react";
import { Switch } from "react-router-dom";

import { Route, Redirect } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

import Homepage from "./containers/Homepage";
import Pricing from "./containers/Pricing";
import Shows from "./containers/Shows";
import Support from "./containers/Support";
import NotFound from "./containers/NotFound";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Homepage} props={childProps} />

    <AppliedRoute path="/pricing/" exact component={Pricing} props={childProps} />

    <AppliedRoute path="/support/" exact component={Support} props={childProps} />

    <AppliedRoute path="/shows/" exact component={Shows} props={childProps} />

    <Route path="/getstarted/" exact>
      <Redirect to="/" />
    </Route>

    {/* Finally, catch all unmatched routes */}
    <AppliedRoute component={NotFound} props={childProps} />
  </Switch>
);

import React from 'react';

const sizeOptions = {
  small: '0.75rem',
  medium: '1rem',
  large: '1.25rem',
  huge: '2.00rem',
  massive: '3rem'
};

const lineHeightOptions = {
  small: '0.9rem',
  medium: '1.325rem',
  large: '1.65rem',
  huge: '2.2rem',
  massive: '3.5rem'
};

export default ({ size, ...props }) => (
  <p
    style={{ fontSize: sizeOptions[size], lineHeight: lineHeightOptions[size] }}
  >
    {props.children}
  </p>
);

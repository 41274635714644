import React, { Component } from "react";
import { Segment, Container, Header, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import MiniMasthead from "../components/MiniMasthead";
import MetaTags from "../components/MetaTags";
import config from "../config";

export default class Support extends Component {
  render() {
    const meta = {
      title: "Support and FAQs | Green Ocean",
      description:
        "Frequently Asked Questions and if you do not find your answer here, feel free to email us directly using the provided Contact Us form.",
      url: "/support/",
    };

    const metaProps = { ...this.props, ...meta };

    return (
      <div>
        <MetaTags {...metaProps} />

        <MiniMasthead text="Support" />

        <Container style={{ marginTop: "2em" }}>
          <Segment vertical>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width="10">
                  <Header as="h2"> FAQs</Header>

                  <Header as="h4">What content do you offer?</Header>
                  <p>
                    We provide exclusive, on-demand, video-based content designed for your specific
                    real estate career stage. Our{" "}
                    <Link to="/shows/">ever-growing list of shows</Link> cover a wide range of
                    topics.
                  </p>

                  <Header as="h4">What's the difference between a Show and an Episode?</Header>
                  <p>
                    Just like regular TV, we have many shows and each show has several episodes.
                  </p>
                  <p>
                    Each show has a theme, such as marketing, social networks, finances, etc. Each
                    episode usually covers a specific topic within that theme.
                  </p>

                  <Header as="h4">How many shows are there?</Header>
                  <p>
                    We're adding <Link to="/shows/">shows and episodes</Link> all the time. Your
                    subscription includes access to all shows and new episodes of those shows.
                  </p>

                  <Header as="h4">What if I change my mind after I subscribe?</Header>
                  <p>
                    You can cancel your subscription at any time from the Accounts screen and you
                    will not be billed again.
                  </p>

                  <Header as="h4">Will Green Ocean TV work with my PC and browser?</Header>
                  <p>Our shows play in any modern browser on Mac, PC, iOS and Android.</p>
                </Grid.Column>
                <Grid.Column width="2" />
                <Grid.Column width="4">
                  <Header as="h2"> Contact Us</Header>

                  <Header as="h4">Still have questions?</Header>

                  <p>
                    You can <a href={`${config.app.URL}/contactus`}>email us</a>, we'd love to hear
                    from you!
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import MiniMasthead from '../components/MiniMasthead';

export default () => (
  <div>
    <Helmet>
      <title>Page Not Found | Green Ocean</title>
    </Helmet>
    <MiniMasthead text="Not Found" />
    <Container>
      <Segment basic textAlign="center" style={{ marginTop: '2rem' }}>
        <Header as="h3">We seem to have lost our signal...</Header>
      </Segment>

      <Segment basic>
        <video
          autoplay="autoplay"
          loop="true"
          style={{ height: '100%', width: '100%' }}
          muted="muted"
        >
          <source
            src="https://www.greenocean.tv/assets/video/test-card-static.mp4"
            type="video/mp4"
          />
        </video>
      </Segment>
    </Container>
  </div>
);

export default {
  facebook: {
    API_KEY: process.env.REACT_APP_FACEBOOK_API_KEY
  },
  home: {
    URL: process.env.REACT_APP_HOMEPAGE_URL
  },
  app: {
    URL: process.env.REACT_APP_WEBAPP_URL
  },
  heap: {
    API_KEY: process.env.REACT_APP_HEAP_ANALYTICS_ID
  },
  podcast: {
    EPISODE_ID: process.env.REACT_APP_PODCAST_EPISODE_ID
  },
};

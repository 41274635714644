import React from "react";

import "./Polygon.css";

export default (props) => (
  <svg
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    className="Polygon-polygon"
  >
    <defs>
      <filter id="f1" x="0" y="0">
        <feGaussianBlur in="SourceGraphic" stdDeviation="0.05" />
      </filter>
    </defs>
    <polygon points={props.points} filter="url(#f1)" />
  </svg>
);

import React from "react";

import "./MastheadCarousel.css";

export default (props) => (
  <div className="MastheadCarousel-container">
    <figure />
    <figure />
    <figure />
    <figure />
    <figure />
  </div>
);

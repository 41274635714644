import React, { Component } from "react";
import { Helmet } from "react-helmet";

import PricingDetail from "../components/PricingDetail";
import MiniMasthead from "../components/MiniMasthead";

export default class Pricing extends Component {
  render() {
    const { getCanonicalLink } = this.props;

    const canonical = getCanonicalLink("/pricing/");

    return (
      <div>
        <Helmet>
          <title>Pricing Plans | Green Ocean</title>
          <link rel="canonical" href={canonical} />
          <meta
            name="description"
            content="Start your 7-day free trial now, with Agent, Team and Office pricing plans available."
          />
        </Helmet>
        <MiniMasthead text="Pricing" />
        <PricingDetail />
      </div>
    );
  }
}

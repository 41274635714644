import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import App from './App';

const rootElement = document.getElementById('root');

const app = (
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>
);

ReactDOM.render(app, rootElement);

import React, { Component } from "react";
import { Container, Header, Segment, Grid, Image, Button } from "semantic-ui-react";
import { Helmet } from "react-helmet";

import Text from "../components/Text";
import config from "../config";

import "./Shows.css";

export default class Shows extends Component {
  render() {
    const { getCanonicalLink } = this.props;

    const canonical = getCanonicalLink("/shows/");

    return (
      <div>
        <Helmet>
          <title>Shows and Topics | Green Ocean</title>
          <link rel="canonical" href={canonical} />
          <meta
            name="description"
            content="Streaming on-demand shows presented by inspiring thought leaders on topics such as marketing, technology, sales scripts, lead generation and even psychology to give you a competitive edge."
          />
        </Helmet>

        <Segment inverted vertical className="Shows-Masthead">
          <Container>
            <Image
              src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,h_800/v1591160744/presenters/russ-01.jpg"
              fluid
              className="Shows-Masthead-shadow"
            />
            <Container className="Shows-Masthead-header Shows-Masthead-header-tablet">
              <Header inverted as="h5">
                Featured Show
              </Header>
              <Header inverted as="h2" style={{ marginTop: "0em" }}>
                The Evolution of Real Estate
              </Header>
              <Text size="large">Russ Cofano</Text>
            </Container>
          </Container>
        </Segment>

        <Segment
          basic
          className="Shows-Segment-Padded Shows-Segment-Grey Shows-Masthead-header-mobile"
        >
          <Container className="Shows-Masthead-header">
            <Header as="h5" style={{ marginBottom: "0" }}>
              Featured Show
            </Header>
            <Header as="h2" style={{ marginTop: "0", marginBottom: "0" }}>
              The Evolution of Real Estate
            </Header>
            <Text size="large">Russ Cofano</Text>
          </Container>
        </Segment>

        <Segment basic className="Shows-Segment-Padded">
          <Container align="center">
            <Header as="h1">Real Estate has Changed</Header>
            <Text size="large">You may need to make some changes, too. It's why we're here.</Text>
            <Button as="a" primary size="huge" href={`${config.app.URL}/signup`}>
              Start Watching
            </Button>
          </Container>
          <Container style={{ paddingTop: "4em", paddingBottom: "2em" }}>
            <Grid stackable columns={3} className="Shows-Grid">
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160742/presenters/hien-01.jpg" />
                <Header as="h4">Leadership Development</Header>
                <Text size="medium">
                  Coaching for real estate managers &amp; brokers, team leaders, and aspiring
                  agents.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160785/presenters/adam-01.jpg" />
                <Header as="h4">Lead Generation</Header>
                <Text size="medium">
                  Business Development &amp; Higher Productivity strategies for real estate brokers,
                  teams, and agents.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160745/presenters/ron-01.jpg" />
                <Header as="h4">Technology Trends</Header>
                <Text size="medium">
                  The Best in real estate software / hardware, tools, platforms, and vendor
                  solutions.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160742/presenters/helen-01.jpg" />
                <Header as="h4">Thought Leadership</Header>
                <Text size="medium">
                  Industry experts, success stories, and mindset practices for business and life.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160745/presenters/jason-01.jpg" />
                <Header as="h4">Marketing &amp; Branding</Header>
                <Text size="medium">
                  Real Estate CRM, Social Media &amp; VIDEO best practices, and modern SEO/SEM
                  tactics.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto,w_900/v1591160743/presenters/howard-01.jpg" />
                <Header as="h4">Business Systems</Header>
                <Text size="medium">
                  Listing and buyer presentations, time management, recruiting &amp; retention
                  strategies for real estate.
                </Text>
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
